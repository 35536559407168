import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby";
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid'

const Anteriorsiguienterecetas = ({ slugreceta }) => {
  const data = useStaticQuery(graphql`
    {
        listados:allStrapiArticle (sort: {order: DESC, fields: published_at}) {
            edges {
                next {
                    Seo {
                        shareImage {
                            localFile {
                                childImageSharp {
                                  gatsbyImageData(placeholder: NONE, quality: 7, sizes: "1080")
                                }
                              }
                        }
                    }
                    slug
                    title
                }
                node {
                    Seo {
                        shareImage {
                            localFile {
                                childImageSharp {
                                  gatsbyImageData(placeholder: NONE, quality: 7, sizes: "1080")
                                }
                              }
                        }
                    }
                    slug
                    title
                }
                previous {
                    Seo {
                        shareImage {
                            localFile {
                                childImageSharp {
                                  gatsbyImageData(placeholder: NONE, quality: 7, sizes: "1080")
                                }
                              }
                        }
                    }
                    slug
                    title
                }
            }
            } 
    }
  `)
//   return <pre>{JSON.stringify(data, null, 4)}</pre>}

    const listado = data.listados.edges;
    // const salidalistado = listado.map(function(salida) {
    // return salida;
    // });
    // console.log(salidalistado);

      return (  
      <div className="bg-white">
          <div className="mx-auto py-2 px-0 max-w-7xl sm:px-0 lg:px-0">
          <div className="grid grid-cols-1">
              <div>
                    {listado.filter(receta => receta.node.slug == slugreceta).map(recetafiltrada => (
                        <div key={recetafiltrada.node.slug}>
                        {recetafiltrada.previous && 
                        <div>
                            <div className="fixed top-1/3 hidden xl:block xl:left-4 2xl:left-30 md:shadow-lg">
                                <Link to={`/recetas/${recetafiltrada.previous.slug}`}>
                                    <GatsbyImage
                                        alt={`${recetafiltrada.previous.title}`}
                                        image={
                                            recetafiltrada.previous.Seo.shareImage.localFile.childImageSharp
                                        .gatsbyImageData
                                        }
                                        className="w-32 object-cover aspect-w-6 aspect-h-4"
                                    />
                                    <div className="">
                                    <div className="text-black font-medium text-s12 pt-1 px-2 pb-1 w-32 leading-4 line-clamp-3">{recetafiltrada.previous.title}</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="fixed bottom-0 xl:hidden bg-white w-1/2 ">
                                <Link to={`/recetas/${recetafiltrada.previous.slug}`}>
                                    <GatsbyImage
                                        alt={`${recetafiltrada.previous.title}`}
                                        image={
                                            recetafiltrada.previous.Seo.shareImage.localFile.childImageSharp
                                        .gatsbyImageData
                                        }
                                        className="w-full object-cover aspect-w-7 aspect-h-3 mt-2 ml-2"
                                    />
                                    <div className="flex items-center">
                                    <ChevronLeftIcon className="h-8 w-5 text-gray-400 ml-0" aria-hidden="true" /><span className="text-black font-medium text-s12 pt-0">RECETA ANTERIOR</span> 
                                    </div>
                                </Link>
                            </div>
                        </div>
                        }

                        {recetafiltrada.next && 
                            <div>
                                <div className="fixed top-1/3 hidden xl:block xl:right-4 2xl:right-30 md:shadow-lg pr-0">
                                    <Link to={`/recetas/${recetafiltrada.next.slug}`}>
                                        <GatsbyImage
                                            alt={`${recetafiltrada.next.title}`}
                                            image={
                                                recetafiltrada.next.Seo.shareImage.localFile.childImageSharp
                                            .gatsbyImageData
                                            }
                                            className="w-32 object-cover aspect-w-6 aspect-h-4"
                                        />
                                        <div className="">
                                        <div className="text-black font-medium text-s12 pt-1 px-2 pb-1 w-32 leading-4 line-clamp-3">{recetafiltrada.next.title}</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="fixed bottom-0 bg-white xl:hidden right-0 w-1/2">
                                    <Link to={`/recetas/${recetafiltrada.next.slug}`}>
                                        <GatsbyImage
                                            alt={`${recetafiltrada.next.title}`}
                                            image={
                                                recetafiltrada.next.Seo.shareImage.localFile.childImageSharp
                                            .gatsbyImageData
                                            }
                                            className="w-full object-cover aspect-w-7 aspect-h-3 mt-2 ml-2"
                                        />
                                        <div className="flex items-center float-right place-items-end">
                                            <span className="text-black font-medium text-s12 pt-0 text-right">SIGUIENTE RECETA</span> <ChevronRightIcon className="h-8 w-5 text-gray-400 text-right" aria-hidden="true" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }          
                        </div>
                    ))}
                </div>
          </div>
          </div>
      </div>
  )
        
}     

    export default Anteriorsiguienterecetas;
