import React, {useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Moment from "react-moment";
import parse from 'html-react-parser'
import Layout from "../../components/layout";
import GoogleAds from "../../components/googleads";
import Ingredientes from "../../components/ingredientes";
import Meriendasquery from "../../components/gridmeriendas";
import Masvistos from "../../components/masvistos";
import StructuredData from "../../components/structuredDataRecipe";
import Newsletter from "../../components/newsletter";
import Anteriorsiguienterecetas from "../../components/anteriorsiguienterecetas";
import Gridreellateral from "../../components/reelslateral";

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      strapiId
      title
      slug
      Instrucciones_Receta
      Receta_personal
      Tiempo_Total_minutos
      Tiempo_preparacion_minutos
      Rinde
      Pasos_A_Seguir
      Azucares_Anadidos
      Titulo_Cuadro_Blanco
      Circulo_Rojo
      Texto_Cuadro_Blanco
      dificultad {
        Etiqueta
      }
      content
      published_at (formatString: "YYYY-MM-DD")
      Titular_imagen_abajo
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
      Tipo_de_cocina {
        Etiqueta
      }
      tipo_de_comidas {
        Etiqueta
      }
      author {
        name
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 30)
            }
          }
        }
      }
      grid_fotos_textos {
        id
        orden
        texto
        titulo
        detalle
        imagen {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED,placeholder: NONE)
            }
          }
        }
      }
      Seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      Ingredientes {
        Ingrediente
        Cantidad
      }
      Imagen_abajo {
        url
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    }
  }
`;

const Article = ({ data }) => {

  const article = data.strapiArticle;  
  const ingredientes = data.strapiArticle.Ingredientes;
  const gridmeriendas = data.strapiArticle.grid_fotos_textos;
  const meriendas = article.slug;

  const richsnippets = {
    valido:true,
    titulo: article.title,
    preparacion: article.Tiempo_preparacion_minutos,
    tiempococina: article.Tiempo_Total_minutos,
    fechapublicado: article.published_at,
    descripcion: article.Receta_personal,
    descripcionsoja: article.Instrucciones_Receta,
    imagen: article.Seo.shareImage,
    ingredientesreceta: article.Ingredientes,
    raciones: article.Rinde,
    instrucciones: article.Instrucciones_Receta,
    tipococina: article.Tipo_de_cocina[0],
    tipoplato: article.tipo_de_comidas[0]
  }
   // console.log(richsnippets.tipoplato);
  const seo = {
    metaTitle: article.Seo.metaTitle,
    metaDescription: article.Seo.metaDescription,
    shareImage: article.Seo.shareImage,
    article: true,
    url: "/recetas/" + article.slug
  };
  // const imagenabajo = data.strapiArticle.Imagen_abajo.url;


  return (
    <Layout seo={seo}>
      <StructuredData richsnippets={richsnippets} />
      <div className="relative bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="flex-initial text-base max-w-screen-lg mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="col-span-3 ...">
                <a href="https://lacocinasana.com/clubcocinasana/" target="_blank">
                  <img
                       alt="baja de peso sin dietas"
                       src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/nuevoanuncio_3590cef121.jpg"
                       className="w-full mb-4 hidden md:block"
                    />
                    <img
                       alt="baja de peso sin dietas"
                       src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/movil_metodo_cocina_sana_2aea3ccd28.jpg"
                       className="w-full mb-4 block md:hidden"
                    />   
                </a>

                <GatsbyImage
                  alt={`${article.title}`}
                  image={article.image.localFile.childImageSharp.gatsbyImageData}
                  className="w-full object-cover aspect-w-6 md:aspect-h-3 aspect-h-5"
                />
              
              {(meriendas != "meriendas-sanas-para-la-vuelta-al-cole" && meriendas != "4-ideas-exquisitas-y-sanas-con-soja-texturizada" && meriendas !="chips-de-platano-macho-al-horno-y-chips-de-pina-al-horno" && meriendas != "huevo-3-ideas-sanas-de-hacerlo" && meriendas != "4-ideas-sanas-refrescantes-con-sandia" && meriendas != "polos-de-gazpacho" && meriendas != "como-preparar-la-mariscada-gallega-perfecta" ) && <div className="relative py-8 bg-white overflow-hidden">
                <div className="relative px-2 sm:px-0 lg:px-0">
                  <div className="text-base mx-auto">
                  <div >
                    <div>
                      <h1 className="mt-0 block text-s28 text-left leading-8 poppins  text-gray-900">{article.title}</h1>
                      <div className="border-b-4 border-amarillolineas mt-4 "></div>
                      <div className="mt-4 text-sm uppercase"><span className="font-bold">RINDE: </span>{article.Rinde}</div>
                      <div className="text-sm mt-1 uppercase"><span className="font-bold">PREPARACIÓN: </span>{article.Tiempo_preparacion_minutos} minutos</div>
                      <div className="text-sm mt-1 uppercase"><span className="font-bold">TIEMPO TOTAL: </span>{article.Tiempo_Total_minutos} minutos</div>
                      <div className="text-sm mt-1 uppercase"><span className="font-bold">AZÚCARES AÑADIDOS: </span>{article.Azucares_Anadidos}</div>
                      <div className="text-sm mt-1 uppercase"><span className="font-bold">DIFICULTAD: </span>{article.dificultad.Etiqueta}</div>
                      <div className="text-sm mt-4 bg-griscaja px-3 py-1.5"><span className="font-bold">RESUMEN PASOS: </span>{article.Pasos_A_Seguir}</div>      

                      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                        <div className="col-span-2 ...">
                          <div className="px-4 md:px-0"><div className="mt-8 mb-4 text-sm circularblack">INGREDIENTES</div><Ingredientes ingredientes={ingredientes} /></div></div>
                          <div className="mt-8 text-base leading-6 px-4 md:px-8 xl:pl-8 xl:border-l xl:border-gray-200 col-span-3 ..." dangerouslySetInnerHTML={{__html: article.Instrucciones_Receta}}></div>
                      </div>
                      <div className="bg-gray-50 px-4 py-4 md:px-9 md:py-9">
                        {article.Imagen_abajo &&
                          <GatsbyImage
                          alt={`${article.Titular_imagen_abajo}`}
                          image={
                            article.Imagen_abajo.localFile.childImageSharp
                            .gatsbyImageData
                          }
                          className="h-full w-full object-cover rounded"
                        />}
                        <div className="text-sm mt-4 flex items-center">
                          <span className="ml-2">COCINA: </span>
                        {article.author.picture && (
                            <GatsbyImage
                              image={
                                article.author.picture.localFile.childImageSharp
                                  .gatsbyImageData
                              }
                              alt={`Autor ${article.author.name}`}
                              style={{ borderRadius: "50%" }}
                              className="mx-2"
                            />
                          )} 
                          <span className="uppercase">
                            {article.author.name}
                          </span>
                        </div>
                        <div className="border-b-4 border-amarillolineas mt-4 "></div>
                        <GoogleAds/>
                        <h2 className="text-2xl mt-8 poppins font-medium">{`${article.Titular_imagen_abajo}`}</h2>
                        <div className="mt-4 text-s22 leading-8 archebook recetapersonal" dangerouslySetInnerHTML={{__html: article.Receta_personal}}></div> 
                      </div>
                      </div>
                      
                    </div>
                  </div>
                </div> 
              </div>} 

              {(meriendas == "meriendas-sanas-para-la-vuelta-al-cole" || meriendas == "4-ideas-exquisitas-y-sanas-con-soja-texturizada" || meriendas== "chips-de-platano-macho-al-horno-y-chips-de-pina-al-horno" || meriendas == "huevo-3-ideas-sanas-de-hacerlo" || meriendas == "4-ideas-sanas-refrescantes-con-sandia" || meriendas == "polos-de-gazpacho" || meriendas == "como-preparar-la-mariscada-gallega-perfecta") && <div className="relative py-8 bg-white overflow-hidden">
                <div className="relative px-2 sm:px-0 lg:px-0">
                  <div className="text-base">
                  <div>
                    <div>
                      <h2 className="mt-2 block text-s21 w500 poppins text-center leading-6  text-rojo">{article.Titulo_Cuadro_Blanco}</h2>
                      <h1 className="mt-2 block mx-auto text-3xl text-center leading-9 poppins w500 text-gray-900 sm:text-s31 uppercase">{article.Circulo_Rojo}</h1>
                      <div className="circularlight mt-2 block mx-auto text-2xl text-center leading-7 underline">{article.Texto_Cuadro_Blanco}</div>
                      <div className="mt-8 text-s22 leading-8 archebook recetapersonal" dangerouslySetInnerHTML={{__html: article.Instrucciones_Receta}}></div>
                      {/* <div className="hover:opacity-75 mb-12">
                        <a href="https://lacocinasana.com/clubcocinasana/tienda-de-la-cocina-sana/" target="_blank">
                            <img
                                alt="meriendas saludables"
                                src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/libro_meriendas_9cf56bde7a.jpg"
                                className="mx-auto w-full"
                            />  
                        </a>
                      </div> */}
                      <Meriendasquery gridmeriendas={gridmeriendas} />
                        <div className="mt-4 text-s22 leading-8 archebook recetapersonal" dangerouslySetInnerHTML={{__html: article.Receta_personal}}></div> 
                      </div>                  
                    </div>
                  </div>
                </div> 
              </div>   } 
              </div>
              <div className="md:pl-6 md:ml-2 md:border-l">
                  <div className="text-marron poppins w500 bg-amarillo text-xl text-center py-3">LO MÁS VISTO</div>
                  <Masvistos/>
                  <div className="text-marron poppins w500 bg-amarillo text-xl text-center py-3 mt-8">VÍDEO RECETAS</div>
                  <Gridreellateral/>
                  <GoogleAds/>
                  <a href="https://lacocinasana.com/clubcocinasana/calculadora-tasa-metabolica-basal/" target="_blank">
                  <img
                      alt="calculadora metabólica basal"
                      src='https://recetascocinasana.s3.eu-west-1.amazonaws.com/calculadora_metabolica_57af3dface.jpg'
                      className="pb-4 pt-4 mx-auto"
                  /> 
                  </a>
              </div>
              </div>
          </div>
        </div>
      </div>
      <Anteriorsiguienterecetas slugreceta = {meriendas} />  
      <Newsletter />            
    </Layout>
  );
};
export default Article;
