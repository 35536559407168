import React from "react"
import { Helmet } from "react-helmet"

const StructuredData = ({ richsnippets = {} }) => {
  const ingredientearray=[];
  if(!richsnippets.preparacion){richsnippets.preparacion=5;}
  if(!richsnippets.tiempococina){richsnippets.tiempococina=5;}
  if(!richsnippets.tipococina){richsnippets.tipococina="Española";} else {richsnippets.tipococina = richsnippets.tipococina.Etiqueta;}
  if(!richsnippets.tipoplato){richsnippets.tipoplato="Almuerzo";} else {richsnippets.tipoplato = richsnippets.tipoplato.Etiqueta;}

  richsnippets.ingredientesreceta.map(ingrediente => ingredientearray.push(ingrediente.Cantidad===null?ingrediente.Ingrediente:(ingrediente.Cantidad + ' ') + ingrediente.Ingrediente))

  if(richsnippets.titulo == "14 Meriendas sanas para la vuelta al cole"){
    ingredientearray.push ("Manzana","crema de cacahuete","galletas de avena","chips de plátano macho","Higos","yogurt","Melón y sandía", "plátano","chips de batata","guacamole","FRUTA CON CREMA DE FRUTOS SECOS");
  }

  if(richsnippets.titulo == "Ideas exquisitas y sanas con soja texturizada"){
    ingredientearray.push ("Soja texturizada","Salsa de tomate","Calabacín crudo","Aguacate","Chile en polvo","Aceitunas verdes","Tomate azul", "quedócomo");
    richsnippets.descripcion = richsnippets.descripcionsoja;
  }

  if(richsnippets.titulo == "Chips de plátano macho y de piña al horno"){
    ingredientearray.push ("Plátano macho","Piña","Aguacate");
    richsnippets.descripcion = richsnippets.descripcionsoja;
  }

  if(richsnippets.titulo == "El huevo cocido"){
    ingredientearray.push ("Huevos","Guisantes","Acelga","Pimientos","Brócoli","Aceite de oliva");
    richsnippets.descripcion = richsnippets.descripcionsoja;
  }

  if(richsnippets.titulo == "4 Ideas refrescantes con sandía"){
    ingredientearray.push ("Sandía","Chocolate negro","Queso fresco","Almendras");
    richsnippets.descripcion = richsnippets.descripcionsoja;
  }

  if(richsnippets.titulo == "Polos de gazpacho para mantener la línea"){
    ingredientearray.push ("Gazpacho");
    richsnippets.descripcion = richsnippets.descripcionsoja;
  }

  if(richsnippets.titulo == "Como preparar la mariscada gallega perfecta"){
    ingredientearray.push ("Langostinos","Almejas","Gambas","Pulpo","Percebes","Cigalas","Gambas","Nécoras grandes","Centollos","Langosta","Bogavantes","Buey de mar" );
    richsnippets.descripcion = richsnippets.descripcionsoja;
  }
  


  return (
    <Helmet>
      {(richsnippets.valido ? true : null) && (
        <script type="application/ld+json">
          {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Recipe",
              "author": "Paola Procell",
              "cookTime": "PT" + richsnippets.tiempococina + "M",
              "datePublished": richsnippets.fechapublicado,
              "description": richsnippets.descripcion,
              "image": (process.env.GATSBY_ROOT_URL || "http://localhost:8000/") +
              richsnippets.imagen.localFile.publicURL,
              "recipeIngredient": ingredientearray,
              "name": richsnippets.titulo,
              "prepTime": "PT" + richsnippets.preparacion + "M",
              "recipeInstructions": richsnippets.instrucciones,
              "recipeYield": richsnippets.raciones,
              "suitableForDiet": "https://schema.org/LowFatDiet",
              "keywords":richsnippets.titulo,
              "recipeCuisine": richsnippets.tipococina,
              "recipeCategory": richsnippets.tipoplato
          })}
        </script>
      )}
    </Helmet>
  )
}
export default StructuredData