import React from "react";
import parse from 'html-react-parser'
import { GatsbyImage } from "gatsby-plugin-image";


const Meriendasquery = ({ gridmeriendas }) => {
    return (
        <div className="mb-2 leading-6 text-base ">{gridmeriendas.map(gridmeriendas => <div key={gridmeriendas.id}>
            {gridmeriendas.imagen &&
                    <GatsbyImage
                    alt={`${gridmeriendas.texto}`}
                    image={
                        gridmeriendas.imagen.localFile.childImageSharp
                      .gatsbyImageData
                    }
                    className="h-full w-full object-cover rounded"
                  />}
            <div className="mt-4 mb-8">
            <h3 className="text-gray-600 text-xl circularblack">{gridmeriendas.titulo} </h3>
            <span className="text-gray-600 text-xl circularlight recetapersonal">{parse(gridmeriendas.texto)} </span>
            <div className="border-b-2 border-gray-200 mt-4 "></div>
            </div>
            </div>)}</div>
    )
}

export default Meriendasquery;