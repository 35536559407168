import React from "react";



const Ingredientesquery = ({ ingredientes }) => {
    return (
        <ul className="list-disc mb-2 leading-6 text-base ingredientes px-4 list-outside">{ingredientes.map(ingrediente => <li key={ingrediente.Ingrediente} className="text-amarillobullet"><span className="text-gray-600 font-medium">{ingrediente.Cantidad} {ingrediente.Ingrediente}</span></li>)}</ul>
    )
}

export default Ingredientesquery;