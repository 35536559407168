import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby"

const Masvistos = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiArticle(
        filter: {visualizacion_mas_vistos: {ne: null}}
        sort: {order: ASC, fields: visualizacion_mas_vistos}
      ) {
        nodes {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, quality: 5, sizes: "750")
              }
              publicURL
            }
          }
          slug
          strapiId
          mas_vistos
          visualizacion_mas_vistos
        }
      }
    }
  `)
//   return <pre>{JSON.stringify(data, null, 4)}</pre>
// }

      return (  
      <div className="bg-white">
          <div className="mx-auto py-2 px-0  sm:px-0 lg:px-0">
          <div className="grid grid-cols-1">
              <ul role="list" className="space-y-2 sm:grid sm:grid-cols-1 list-none">
                  {data.allStrapiArticle.nodes.map((receta) => (
                  <li key={receta.strapiId}>
                    <Link to={`/recetas/${receta.slug}`}>
                      <div className="grid grid-cols-3 items-center divide-y-2">
                      <GatsbyImage
                          alt={`${receta.title}`}
                          image={
                              receta.image.localFile.childImageSharp
                          .gatsbyImageData
                          }
                          className="w-24 h-24 md:w-16 md:h-16 col-span-1 mt-2 mx-auto"
                      />
                      <div className="col-span-2 ml-3">
                          <p className="text-black font-xs">Recetas</p>
                          <h3 className="text-base md:text-s14 uppercase w500 leading-4 text-maroon poppins ">{receta.title}</h3>
                      </div>
                      </div>
                    </Link>
                  </li>
                  ))}
              </ul>
          </div>
          </div>
      </div>
  )
        
}     

    export default Masvistos;
