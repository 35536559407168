import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby"

const Gridreellateral = () => {
    const datareels = useStaticQuery(graphql`
    {
        allStrapiReels (
            sort: {order: DESC, fields: published_at}
            limit: 2
        ){
            edges {
                node {
                    id
                    slug
                    titulo
                    tiemporeceta
                    published_at
                    badge
                    poster {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                            }
                        }
                    }
                    meta
                }
            }
        }
    }
    `)

      return (  
      <div className="bg-white">
          <div className=" mx-auto py-6  sm:py-4">
          <div className="grid grid-cols-1">
              <ul role="list" className="sm:grid sm:grid-cols-1 list-none gap-4">
                  {datareels.allStrapiReels.edges.map((reels) => (
                  <li key={reels.node.id}>
                    <Link to={`/reels/${reels.node.slug}`}>
                      <div className="items-center relative bg-gradient-to-t from-negrotransparente">
                      <GatsbyImage
                          alt={`${reels.node.titulo}`}
                          image={
                            reels.node.poster.localFile.childImageSharp
                          .gatsbyImageData
                          }
                          className="w-full h-full"       
                      />
                      {reels.node.badge &&
                        <div className="absolute top-0 text-sm px-3 bg-white text-black rounded">{reels.node.badge}</div>
                      }
                      <div className="absolute left-1/2 transform -translate-x-1/2 justify-end  top-1/2 -translate-y-1/2">
                            <img src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/iconoplay_a40a72c71a.png" alt="play" className="w-20" />
                      </div>
                      </div>
                      <div className="text-center">
                        <div className="">
                            <p className="uppercase leading-5 text-s16 text-verde mt-4 pb-0 text-center font-bold">{reels.node.meta}</p>
                            <h3 className="text-s20 leading-6 px-6 md:px-6 archersemibold text-center text-black w500 pb-8 md:pb-0">{reels.node.titulo}</h3>
                        </div>
                      </div>
                      
                    </Link>
                  </li>
                  ))}
              </ul>
          </div>
          </div>
      </div>
  )
        
}     

    export default Gridreellateral;